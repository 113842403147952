/**
 * The below properties for User are commented out for now to match
 * the response we get from /users/me. In the future, if
 * we need the extra data, we can update this type and
 * the endpoint for /users/me
 */
export interface User {
    // created_at: Date;
    email: string;
    // holder_id: null;
    id: string;
    name: string | null;
    phone_number: string | null;
    preferred_name: string | null; // @deprecated - TODO: change to name
    // org_codes aren't always returned. Depends on the endpoint returning the user. ¯\_(ツ)_/¯
    org_codes?: string[]; // Has to be an array of string because we don't know if array contains orgs we can acocunt for on the FE. use a type guard to narrow the type.
    // updated_at: Date;
    // suite_id: string | '';
}

/**
 * @deprecated - TODO: use User instead
 */
export interface Owner {
    // created_at: Date;
    email: string;
    // holder_id: null;
    id: string;
    phone_number: string | null;
    name: string | null;
    // updated_at: Date;
    org_codes?: string[]; // Has to be an array of string because we don't know if array contains orgs we can acocunt for on the FE. use a type guard to narrow the type.
}

// Accurate as of Sept 28, 2022
export enum USER_RESOURCE {
    INTERNAL = 'internal',
}

// Accurate as of Sept 28, 2022
export enum USER_RESOURCE_MODE {
    NONE = 'none',
    RW = 'rw',
}

export interface UserPermission {
    id: string;
    mode: USER_RESOURCE_MODE;
    resource: USER_RESOURCE;
}

export interface Claims {
    email: string;
    exp: number;
    iat: number;
    id: string; // user.id
    iss: string;
    act?: {
        sub: string; // user.id
    };
}

export interface AuthData {
    claims: Claims;
    token: string;
    user: User;
}

export interface OwnerAuthData {
    claims: Claims;
    token: string;
    user: Owner;
}

export interface UserClassifications {
    applicant: boolean;
    owner: boolean;
    resident: boolean;
    freemium: boolean;
    residentFirm: boolean;
}
