import { HouseIcon, MenuItemProps } from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import PublicLayout from '../components/layout/PublicLayout';
import { SequenceProvider } from '../components/sequence/Context';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import NoMatch from '../pages/NoMatch';
import { URLS } from './urls';

const Collection = lazy(() => import('../pages/Collection'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('../pages/auth/ForgotPasswordSuccess'));
const Home = lazy(() => import('../pages/Home'));
const NewPassword = lazy(() => import('../pages/auth/NewPassword'));
const Step = lazy(() => import('../pages/Step'));

/**
 * These are public routes that the app needs. These are used
 * in App.tsx to create these routes.
 */
export const publicRoutes: RouteObject[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: URLS.Login.path,
                element: <Login />,
            },
            {
                path: URLS.ForgotPassword.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPassword />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.ForgotPassword.Success.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPasswordSuccess />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.NewPassword.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <NewPassword />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.Register.path,
                element: <Register />,
            },
            {
                path: '*',
                element: <NoMatch />,
            },
        ],
    },
];

/**
 * These are routes for logged in users only. We use this array
 * to create the protected routes in App.tsx.
 */
export const authRoutes: MenuItemProps[] = [
    {
        path: URLS.Home.path,
        label: 'Home',
        icon: <HouseIcon height={24} width={24} />,
        showInMenu: false,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <Home />
            </Suspense>
        ),
    },
    {
        path: URLS.Collection.path,
        label: 'Collection',
        showInMenu: false,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <SequenceProvider />
            </Suspense>
        ),
        children: [
            {
                path: URLS.Collection.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Collection />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Collection.Step.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Step />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
];
