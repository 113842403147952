// This is a truncated version of the BE Google Drive type drive_v3.Schema$File
export interface Document {
    id?: string;
    name?: string;
    mimeType?: string;
}

// Matches DB enum
export enum DOCUMENT_TYPE {
    APPLICATION_FORM = 'APPLICATION_FORM',
    GOOGLE_DRIVE_PRIVATE_FOLDER = 'GOOGLE_DRIVE_PRIVATE_FOLDER',
    GOOGLE_DRIVE_PUBLIC_FOLDER = 'GOOGLE_DRIVE_PUBLIC_FOLDER',
    IDENTITY_CHECK_REPORT = 'IDENTITY_CHECK_REPORT',
    INVOICE = 'INVOICE',
    LETTER_OF_EMPLOYMENT = 'LETTER_OF_EMPLOYMENT',
    OTHER = 'OTHER',
    OWNER_RESIDENT_AGREEMENT = 'OWNER_RESIDENT_AGREEMENT',
    PAY_STUBS = 'PAY_STUBS',
    PRE_AUTHORIZED_DEBIT_AGREEMENT = 'PRE_AUTHORIZED_DEBIT_AGREEMENT',
    DEPOSIT_AGREEMENT = 'DEPOSIT_AGREEMENT',
    T4 = 'T4',
    HOME_INSPECTION_REPORT = 'HOME_INSPECTION_REPORT',
}

// Matches DB enum
export enum DOCUMENT_STATUS {
    SUBMITTED = 'SUBMITTED',
    AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    OTHER = 'OTHER',
    PENDING = 'PENDING',
}

// Matches DB enum
export enum DOCUMENT_SOURCE {
    MANUAL = 'MANUAL',
    CERTN = 'CERTN',
    OTHER = 'OTHER',
    HELLOSIGN = 'HELLOSIGN',
    PLAID = 'PLAID',
}

// DB
export interface BaseUserDocument {
    id: string;
    user_id: string;
    external_identifier_id: string | null;
    google_drive_url: string | null;
    source: DOCUMENT_SOURCE;
    status: DOCUMENT_STATUS;
    type: DOCUMENT_TYPE;
    created_at: string | null;
    updated_at: string | null;
}

export type DocumentTable = {
    id: string;
    type: DOCUMENT_TYPE;
    status: DOCUMENT_STATUS;
    source: DOCUMENT_SOURCE;
    external_identifier_id: string | null;
    google_drive_url: string | null;
    created_at: string;
    updated_at: string;
};

export type UserDocumentTable = {
    id: string;
    user_id: string;
    document_id: string;
    google_drive_url: string | null;
    created_at: string;
    updated_at: string;
};

// This matches what comes from the GET route after DB joins/manipulation
export type UserDocument = UserDocumentTable & {
    document: DocumentTable;
};

// Matches DB enum
export enum DOCUMENT_REQUEST_STATUS {
    REQUESTED = 'REQUESTED',
    FULFILLED = 'FULFILLED',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
}

export interface DocumentRequest {
    id: string;
    document_type: DOCUMENT_TYPE;
    status: DOCUMENT_REQUEST_STATUS;
    applicant_id: string;
    created_at: string;
    updated_at: string;
}
